$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;


/* 3. Footer ================================= */

#footer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	height: auto;
	background: black;
	padding-top: 20px;
	padding-bottom: 53px;
	border-top: 4px solid black;

	@media( min-width: 768px ) {
	   	padding-bottom: 93px;
	}

	@media( min-width: 1440px ) {
        padding-bottom: 95px;
	}
	
	.title {
		margin: 40px auto 30px;
		font-family: niveau-grotesk, sans-serif;
	    font-size: 46px;
	    font-weight: 500;
	    letter-spacing: 0px;
	    text-align: center;
	    color: white;

		@media( min-width: 768px ) {
    	    margin: 55px auto 45px;
			font-size: 75px;
		}

		@media( min-width: 1440px ) {
    	    margin: 70px auto 55px;
    		font-size: 66px;
		}

		.underTitle {
			position: relative;
			margin: 7px auto 7px;
			width: auto;
			height: 2px;
			background: white;
		}
	}

	.link {
        font-family: niveau-grotesk, sans-serif;
	    font-size: 24px;
	    line-height: 76px;
	    z-index: 400;
	    text-align: center;
	    text-decoration: none;
	    color: white;
	    letter-spacing: .25px;
	    font-weight: 300;
	    transition: .4s;

		@media( min-width: 768px ) {
    	    font-size: 38px;
    		line-height: 123px;
		}

		@media( min-width: 1440px ) {
	        font-size: 33px;
			line-height: 93px;
		}

		&:hover {
			transform: scale(1.2);
			transition: .4s;
			color: #26ffba;
		}
	}
}


























